import form1 from './form-1.json'
import mapAndForm from './map-and-form.json'
import mapAndSocial from './map-and-social.json'
import chat2 from './chat2.json'
import { social1 } from './social-1'
import schedule from './schedule.json'
import schedule2 from './schedule-2.json'

export function getDemoGeneralCmps() {
    return [form1, mapAndSocial, mapAndForm, chat2, social1, schedule, schedule2]
}
