import wap3header from './wap-3-header.json'
import wap3hero from './wap-3-hero.json'
import wap3about from './wap-3-about.json'
import wap3team from './wap-3-team.json'
import wap3services from './wap-3-services.json'
import wap3footer from './wap-3-footer.json'

// export function getWap3Template() {
//     return [wap3header, wap3hero, wap3about, wap3team, wap3services, wap3footer]
// }
export function getDemoCmps3() {
    return [wap3header, wap3hero, wap3about, wap3team, wap3services, wap3footer]
}
